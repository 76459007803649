import React from 'react'
import ImageCircle from './ImageCircle';

interface messageProbs{
    content: string;
    time: string;
    side: 0 | 1;
    userImage: string;
}

export default function Message({content, time, side, userImage}: messageProbs) {
  return (
    <div className={`flex gap-8 ${side === 0 ? 'justify-end' : 'justify-start'}`}>
        {side===1 ? <ImageCircle image={userImage}></ImageCircle> : <></>}
        <div>
            <div className={`${side===0 ? 'bg-primary text-white' : 'bg-white text-black'} max-w-[300px] sm:max-w-[436px] font-[500] text-[14px] leading-[21px] py-12 px-16 rounded-[16px]`}>
                <p>{content}</p>
            </div>
            <p className={`${side===0 ? 'text-right' : 'text-left'} font-[500] text-[12px] text-[#888888] leading-[18px] mt-8`}>{time}</p>
        </div>
        {side===0 ? <ImageCircle image={userImage}></ImageCircle> : <></>}
    </div>
  )
}
