import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {Form} from '@ui/forms/form';
import {AccountSettingsPanel} from '@common/auth/ui/account-settings/account-settings-panel';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {UpdatePasswordPayload, useUpdatePassword} from './use-update-password';
import {Button} from '@ui/buttons/button';
import {Trans} from '@ui/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';

export function ChangePasswordPanel() {
  const form = useForm<UpdatePasswordPayload>();
  const formId = useId();
  const updatePassword = useUpdatePassword(form);
  return (
    <AccountSettingsPanel
      id={AccountSettingsId.Password}
      title={<span className='settings-section-header'><Trans message="Update password" /></span>}
      actions={
        <Button
          type="submit"
          form={formId}
          variant="flat"
          color="primary"
          disabled={!form.formState.isValid || updatePassword.isPending}
        >
          <Trans message="Update password" />
        </Button>
      }
    >
      <Form
        form={form}
        id={formId}
        onSubmit={newValues => {
          updatePassword.mutate(newValues, {
            onSuccess: () => {
              form.reset();
            },
          });
        }}
      >
        <div className='user-settings-field-container mt-0'>
            <label htmlFor="current_password" className='user-settings-input-label'><Trans message="Current Password" /></label>
            <FormTextField
              className="user-settings-text-field"
              name="current_password"
              id='current_password'
              inputClassName='user-settings-input-field'
              type="password"
              autoComplete="current-password"
              required
            />
          </div>
          <hr></hr>
        <div className='user-settings-field-container mt-0'>
            <label htmlFor="password" className='user-settings-input-label'><Trans message="New Password" /></label>
            <FormTextField
              className="user-settings-text-field"
              name="password"
              id='password'
              inputClassName='user-settings-input-field'
              type="password"
              autoComplete="new-password"
              required
            />
          </div>
          <hr></hr>
          <div className='user-settings-field-container mt-0'>
            <label htmlFor="password_confirmation" className='user-settings-input-label'><Trans message="Confirm password" /></label>
            <FormTextField
              className="user-settings-text-field"
              name="password_confirmation"
              id='password_confirmation'
              inputClassName='user-settings-input-field'
              type="password"
              autoComplete="new-password"
              required
            />
          </div>
        {/* <FormTextField
          className="mb-24"
          name="current_password"
          label={<Trans message="Current password" />}
          type="password"
          autoComplete="current-password"
          required
        /> */}
        {/* <FormTextField
          className="mb-24"
          name="password"
          label={<Trans message="New password" />}
          type="password"
          autoComplete="new-password"
          required
        /> */}
        {/* <FormTextField
          name="password_confirmation"
          label={<Trans message="Confirm password" />}
          type="password"
          autoComplete="new-password"
          required
        /> */}
      </Form>
    </AccountSettingsPanel>
  );
}
