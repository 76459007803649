import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { useUser } from '../use-user';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { SocialLoginPanel } from './social-login-panel';
import { BasicInfoPanel } from './basic-info-panel/basic-info-panel';
import { ChangePasswordPanel } from './change-password-panel/change-password-panel';
import { LocalizationPanel } from './localization-panel';
import { AccessTokenPanel } from './access-token-panel/access-token-panel';
import { DangerZonePanel } from './danger-zone-panel/danger-zone-panel';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { AccountSettingsSidenav } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { SessionsPanel } from '@common/auth/ui/account-settings/sessions-panel/sessions-panel';
import { Fragment, useContext, useEffect, useMemo } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { TwoFactorPanel } from '@common/auth/ui/account-settings/two-factor-panel';
import { DashboardSidenav } from '@common/ui/dashboard-layout/dashboard-sidenav';
import { Sidebar } from '@app/drive/layout/sidebar/sidebar';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import ChatIcon from '@app/drive/layout/icons/ChatIcon';
import GearIcon from '@app/drive/layout/icons/GearIcon';
import SignoutIcon from '@app/drive/layout/icons/SignoutIcon';
import { useLogout } from '@common/auth/requests/logout';
import PricePlansIcon from '@app/drive/layout/icons/PricePlansIcon';
import { useDriveStore } from '@app/drive/drive-store';
import { useActiveWorkspaceId } from '@common/workspace/active-workspace-id-context';
import { FileUploadStoreOptions } from '@common/uploads/uploader/file-upload-store';
import { getActiveWorkspaceId } from '@common/workspace/active-workspace-id';
import { useAuth } from '@common/auth/use-auth';
// import { FileUploadProvider } from '@common/uploads/uploader/file-upload-provider';
// import { FileEntryUrlsContext } from '@common/uploads/file-entry-urls';
// import { DashboardLayout } from '@common/ui/dashboard-layout/dashboard-layout';
// import { DriveContentHeader } from '@app/drive/layout/drive-content-header';
// import { DashboardContent } from '@common/ui/dashboard-layout/dashboard-content';
// import { FileView } from '@app/drive/file-view/file-view';
// import { UploadQueue } from '@app/drive/uploading/upload-queue';
// import { DriveDialogsContainer } from '@app/drive/files/dialogs/drive-dialogs-container';
// import { DetailsSidebar } from '@app/drive/details-sidebar/details-sidebar';
// import { EntryDragPreview } from '@app/drive/file-view/entry-drag-preview';

const uploadStoreOptions: FileUploadStoreOptions = {
  modifyUploadedFile: uploadedFile => {
    const workspaceId = getActiveWorkspaceId();
    uploadedFile.fingerprint = `${uploadedFile.fingerprint}-w-${workspaceId}`;
    return uploadedFile;
  },
};

export function AccountSettingsPage() {
  const { auth } = useContext(SiteConfigContext);
  const { mutate: logout } = useLogout();
  const { data, isLoading } = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  const { pathname } = useLocation();
  const { hash } = useParams();
  const { workspaceId } = useActiveWorkspaceId();
  const activePage = useDriveStore(s => s.activePage);
  const urlsContextValue = useMemo(() => {
    return { workspaceId };
  }, [workspaceId]);

  let {hasRole} = useAuth();

  useEffect(() => {
    const container = document.getElementById('settingsPageContainer') as HTMLElement | null;

    if (!container) return; // Ensure the container exists

    const handleScroll = () => {
      const scrollVal = container.scrollTop; // Use scrollTop to get the scroll position
      const tabs = container.querySelectorAll<HTMLElement>('.user-settings-tap');

      tabs.forEach((ele) => {
        const panelId = ele.getAttribute('panel');
        const section = container.querySelector<HTMLElement>(`#${panelId}`);

        if (section) {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;

          // Check if the current scroll position is within this section
          if (scrollVal + 100 >= sectionTop && scrollVal - 100 < sectionTop + sectionHeight) {
            tabs.forEach((element) => element.classList.remove('active'));
            ele.classList.add('active');
            tabs.forEach((element) => {
              if (element.getAttribute('panel') == panelId) {
                element.classList.add('active');
              }
            });
          } else {
            ele.classList.remove('active');
          }
        }
      });
    };

    // Attach the scroll event listener
    container.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // return (
  //   <Fragment>
  //     {activePage?.label && (
  //       <StaticPageTitle>
  //         <Trans
  //           message={
  //             typeof activePage.label === 'string'
  //               ? activePage.label
  //               : activePage.label.message
  //           }
  //         />
  //       </StaticPageTitle>
  //     )}
  //     <FileUploadProvider options={uploadStoreOptions}>
  //       <FileEntryUrlsContext.Provider value={urlsContextValue}>
  //         <div className='flex w-full bg-main'>
  //           <div className='h-[100vh] min-w-[90px] py-20 hidden lg:flex flex-col justify-between items-center'>
  //             <div className="flex flex-col items-center justify-center gap-20">
  //               <NavLink to={'/admin'} className={'side-tabs-icon'}>
  //                 <i className="fa-solid fa-user-tie "></i>
  //               </NavLink>
  //               <NavLink to={'/drive'} className={'side-tabs-icon'}>
  //                 <i className="fa-regular fa-folder-open "></i>
  //               </NavLink>
  //               <NavLink to={'/chat'} className={'side-tabs-icon'}>
  //                 <ChatIcon></ChatIcon>
  //               </NavLink>
  //               <NavLink to={'/plans'} className={'side-tabs-icon'}>
  //                 <PricePlansIcon></PricePlansIcon>
  //               </NavLink>
  //               <NavLink to={'/account-settings'} className={'side-tabs-icon'}>
  //                 <GearIcon></GearIcon>
  //               </NavLink>

  //             </div>
  //             <button className='cursor-pointer' onClick={() => logout()}>
  //               <SignoutIcon></SignoutIcon>
  //             </button>
  //           </div>
  //           <div className='flex-grow ms-auto lg:rounded-tl-[100px] lg:rounded-bl-[100px] overflow-x-hidden bg-alt'>
  //             <DashboardLayout
  //               name="drive"
  //               onDragOver={e => {
  //                 e.preventDefault();
  //                 e.stopPropagation();
  //                 e.dataTransfer.dropEffect = 'none';
  //               }}
  //               onDrop={e => {
  //                 // prevent files from desktop from blowing away the document
  //                 e.preventDefault();
  //               }}
  //             >
  //               {/* <NavbarSearch /> */}
  //               <span className='w-screen z-50'>
  //                 <Navbar />
  //               </span>
  //               <DashboardSidenav position="left" size="md">
  //                 <Sidebar />
  //               </DashboardSidenav>
  //               {/* <DriveContentHeader /> */}

  //               {/* <DashboardContent> */}
  //                   <div className='absolute lg:ms-[100px] lg:mt-0 overflow-hidden'>

  //                 <div className='overflow-x-hidden lg:ml-[90px] h-[100vh]' id='settingsPageContainer'>
  //                   <div className="lg:ms-[50px] lg:mt-20">
  //                     <StaticPageTitle>
  //                       <Trans message="Account Settings" />
  //                     </StaticPageTitle>
  //                     <div>
  //                       <div className="px-24 py-24">
  //                         <h1 className="text-3xl">
  //                           <Trans message="Account settings" />
  //                         </h1>
  //                         <div className="mb-40 text-base text-muted">
  //                           <Trans message="View and update your account details, profile and more." />
  //                         </div>
  //                         {isLoading || !data ? (
  //                           <ProgressCircle
  //                             className="my-80"
  //                             aria-label="Loading user.."
  //                             isIndeterminate
  //                           />
  //                         ) : (
  //                           <div className="flex">

  //                             <div className='w-full'>
  //                               <AccountSettingsSidenav />
  //                               <main className="flex-auto mb-[530px]">
  //                                 {auth.accountSettingsPanels?.map(panel => (
  //                                   <panel.component key={panel.id} user={data.user} />
  //                                 ))}
  //                                 <BasicInfoPanel user={data.user} />
  //                                 <SocialLoginPanel user={data.user} />
  //                                 <ChangePasswordPanel />
  //                                 <TwoFactorPanel user={data.user} />
  //                                 <SessionsPanel />
  //                                 <LocalizationPanel user={data.user} />
  //                                 <AccessTokenPanel user={data.user} />
  //                                 <DangerZonePanel />
  //                               </main>
  //                             </div>
  //                           </div>
  //                         )}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                   </div>
  //                 {/* <FileView /> */}
  //                 {/* <div>HIIII</div> */}
  //               {/* </DashboardContent> */}
  //               <UploadQueue />

  //               <DriveDialogsContainer />
  //               {/* <DashboardSidenav position="right" size="lg">
  //                 <DetailsSidebar />
  //               </DashboardSidenav> */}
  //             </DashboardLayout>
  //           </div>
  //         </div>
  //       </FileEntryUrlsContext.Provider>
  //       <EntryDragPreview />
  //     </FileUploadProvider>
  //   </Fragment>
  // );

  return (
    <>
      <div className='flex bg-primary'>
        <div className='h-[100vh] w-[90px] py-20 hidden lg:flex flex-col justify-between items-center fixed top-0 bottom-0 left-0 right-[90px]'>
          <div className="flex flex-col items-center justify-center gap-20">
          {hasRole(1) ? <></> : <NavLink to={'/admin'} className={'side-tabs-icon'}>
                    <i className="fa-solid fa-user-tie "></i>
                  </NavLink>}
            <NavLink to={'/drive'} className={'side-tabs-icon'}>
              <i className="fa-regular fa-folder-open "></i>
            </NavLink>
            <NavLink to={'/chat'} className={'side-tabs-icon'}>
              <ChatIcon></ChatIcon>
            </NavLink>
            <NavLink to={'/pricing'} className={'side-tabs-icon'}>
              <PricePlansIcon></PricePlansIcon>
            </NavLink>
            <NavLink to={'/account-settings'} className={'side-tabs-icon'}>
              <GearIcon></GearIcon>
            </NavLink>

          </div>
          <button className='cursor-pointer' onClick={() => logout()}>
            <SignoutIcon></SignoutIcon>
          </button>
        </div>

        <div className='flex-grow bg-alt  lg:rounded-tl-[100px] lg:rounded-bl-[100px] overflow-x-hidden lg:ml-[90px] h-[100vh]' id='settingsPageContainer'>
          <div className="lg:ms-[50px] lg:mt-20">
            <StaticPageTitle>
              <Trans message="Account Settings" />
            </StaticPageTitle>
            <Navbar />
            <div>
              <div className="px-24 py-24">
                <h1 className="text-3xl">
                  <Trans message="Account settings" />
                </h1>
                <div className="mb-40 text-base text-muted">
                  <Trans message="View and update your account details, profile and more." />
                </div>
                {isLoading || !data ? (
                  <ProgressCircle
                    className="my-80"
                    aria-label="Loading user.."
                    isIndeterminate
                  />
                ) : (
                  <div className="flex">
                    <div className='w-full'>
                      <AccountSettingsSidenav />
                      <main className="flex-auto mb-[530px]">
                        {auth.accountSettingsPanels?.map(panel => (
                          <panel.component key={panel.id} user={data.user} />
                        ))}
                        <BasicInfoPanel user={data.user} />
                        <SocialLoginPanel user={data.user} />
                        <ChangePasswordPanel />
                        <TwoFactorPanel user={data.user} />
                        <SessionsPanel />
                        <LocalizationPanel user={data.user} />
                        <AccessTokenPanel user={data.user} />
                        <DangerZonePanel />
                      </main>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
