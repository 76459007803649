import { useActiveWorkspaceId } from '@common/workspace/active-workspace-id-context';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { driveState, useDriveStore } from './drive/drive-store';
import { useLogout } from '@common/auth/requests/logout';
import { DRIVE_PAGES, makePartialFolderPage, SearchPage } from './drive/drive-page/drive-page';
import { store } from './lib/store';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { Trans } from '@ui/i18n/trans';
import { FileUploadProvider } from '@common/uploads/uploader/file-upload-provider';
import { FileEntryUrlsContext } from '@common/uploads/file-entry-urls';
import ChatIcon from './drive/layout/icons/ChatIcon';
import PricePlansIcon from './drive/layout/icons/PricePlansIcon';
import GearIcon from './drive/layout/icons/GearIcon';
import SignoutIcon from './drive/layout/icons/SignoutIcon';
import { DashboardLayout } from '@common/ui/dashboard-layout/dashboard-layout';
// import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { DashboardSidenav } from '@common/ui/dashboard-layout/dashboard-sidenav';
import { Sidebar } from './drive/layout/sidebar/sidebar';
import { DriveContentHeader } from './drive/layout/drive-content-header';
import { DashboardContent } from '@common/ui/dashboard-layout/dashboard-content';
import { FileView } from './drive/file-view/file-view';
import { UploadQueue } from './drive/uploading/upload-queue';
import { DriveDialogsContainer } from './drive/files/dialogs/drive-dialogs-container';
import { DetailsSidebar } from './drive/details-sidebar/details-sidebar';
import { EntryDragPreview } from './drive/file-view/entry-drag-preview';
import { Provider } from 'react-redux';
import { FileUploadStoreOptions } from '@common/uploads/uploader/file-upload-store';
import { getActiveWorkspaceId } from '@common/workspace/active-workspace-id';
import { DashboardLayoutContext } from '@common/ui/dashboard-layout/dashboard-layout-context';
import { NavbarSearch } from './drive/search/navbar-search';
import { IconButton } from '@ui/buttons/icon-button';
import { SearchIcon } from '@ui/icons/material/Search';
import { CreateNewButton } from './drive/layout/create-new-button';
import { DashboardNavbar } from '@common/ui/dashboard-layout/dashboard-navbar';
import { CloseIcon } from '@ui/icons/material/Close';
import { EntryActionList } from './drive/entry-actions/entry-action-list';
import ImageCircle from './chat_components/ImageCircle';
import Message from './chat_components/Message';
import ChatContainer from './chat_components/ChatContainer';
import Inbox from './chat_components/Inbox';
import { useAuth } from '@common/auth/use-auth';
import { apiClient } from '@common/http/query-client';
import AddChatModal from './chat_components/AddChatModal';

const uploadStoreOptions: FileUploadStoreOptions = {
    modifyUploadedFile: uploadedFile => {
      const workspaceId = getActiveWorkspaceId();
      uploadedFile.fingerprint = `${uploadedFile.fingerprint}-w-${workspaceId}`;
      return uploadedFile;
    },
  };

export default function Chat() {
    const { pathname } = useLocation();
  const { hash } = useParams();
  const { workspaceId } = useActiveWorkspaceId();
  const activePage = useDriveStore(s => s.activePage);
  const { mutate: logout } = useLogout();
  const [activeBtn, setActiveBtn] = useState(0);
  let auth = useAuth();
  let [modalOpen, setModalOpen] = useState(false);

  
  apiClient.get('/lc/chats').then(data => console.log(data));
  
  
  

  useEffect(() => {
    driveState().setActivePage(
      DRIVE_PAGES.find(p => p.path === pathname) ||
      makePartialFolderPage(hash!),
    );
  }, [pathname, hash]);

  const urlsContextValue = useMemo(() => {
    return { workspaceId };
  }, [workspaceId]);

  useEffect(() => {
    return () => {
      driveState().reset();
    };
  }, []);

  return (
    <Provider store={store}>
    <Fragment>
      {activePage?.label && (
        <StaticPageTitle>
          <Trans
            message={
              typeof activePage.label === 'string'
                ? activePage.label
                : activePage.label.message
            }
          />
        </StaticPageTitle>
      )}
      <FileUploadProvider options={uploadStoreOptions}>
        <FileEntryUrlsContext.Provider value={urlsContextValue}>
          <div className='flex w-full bg-main'>
            <div className='h-[100vh] min-w-[90px] py-20 hidden lg:flex flex-col justify-between items-center'>
                <div className="flex flex-col items-center justify-center gap-20">
                {auth.hasRole(1) ? <></> : <NavLink to={'/admin'} className={'side-tabs-icon'}>
                    <i className="fa-solid fa-user-tie "></i>
                  </NavLink>}
                  <NavLink to={'/drive'} className={'side-tabs-icon'}>
                    <i className="fa-regular fa-folder-open "></i>
                  </NavLink>
                  <NavLink to={'/chat'} className={'side-tabs-icon'}>
                    <ChatIcon></ChatIcon>
                  </NavLink>
                  <NavLink to={'/pricing'} className={'side-tabs-icon'}>
                    <PricePlansIcon></PricePlansIcon>
                  </NavLink>
                  <NavLink to={'/account-settings'} className={'side-tabs-icon'}>
                    <GearIcon></GearIcon>
                  </NavLink>

                </div>
                <button className='cursor-pointer' onClick={()=>logout()}>
                    <SignoutIcon></SignoutIcon>
                </button>
            </div>
            <div className='flex-grow ms-auto lg:rounded-tl-[100px] lg:rounded-bl-[100px] overflow-x-hidden bg-alt'>
              <DashboardLayout
                name="drive"
                onDragOver={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.dataTransfer.dropEffect = 'none';
                }}
                onDrop={e => {
                  // prevent files from desktop from blowing away the document
                  e.preventDefault();
                }}
              >
                {/* <NavbarSearch /> */}
                <Navbar />
                <DashboardSidenav position="left" size="md">
                  <Sidebar />
                </DashboardSidenav>
                {/* <DriveContentHeader /> */}
                
                <DashboardContent>
                  <div className='flex py-32 px-24 gap-24'>
                      <AddChatModal open={modalOpen} setOpen={setModalOpen}></AddChatModal>
                    <div className='hidden md:block'>
                      <Inbox setModalOpen={setModalOpen}></Inbox>
                    </div>
                    <ChatContainer 
                    userImage='https://www.profilebakery.com/wp-content/uploads/2024/05/Profile-picture-created-with-ai.jpeg'
                    userName='Ahmed Esmail'>
                    <Message 
                    content='Risus nec mi, pellentesque pulvinar volutpat pretium orci. Elit, congue aliquam laoreet ullamcorper nunc sit placerat. Varius vitae magna non sit ipsum varius donec bibendum dolor. Et urna blandit posuere ut massa, dictumst in et.'
                    side={0}
                    time='10:00 PM'
                    userImage='https://www.profilebakery.com/wp-content/uploads/2024/05/Profile-picture-created-with-ai.jpeg'
                    ></Message>
                    <Message 
                    content='Risus nec mi, pellentesque pulvinar volutpat pretium orci. Elit, congue aliquam laoreet ullamcorper nunc sit placerat. Varius vitae magna non sit ipsum varius donec bibendum dolor. Et urna blandit posuere ut massa, dictumst in et.'
                    side={1}
                    time='10:00 PM'
                    userImage='https://www.profilebakery.com/wp-content/uploads/2024/05/Profile-picture-created-with-ai.jpeg'
                    ></Message>
                    
                    </ChatContainer>
                  
                    
                  </div>
                </DashboardContent>
                <UploadQueue />
                
                {/* <DriveDialogsContainer /> */}
                {/* <DashboardSidenav position="right" size="lg">
                  <DetailsSidebar />
                </DashboardSidenav> */}
              </DashboardLayout>
            </div>
          </div>
        </FileEntryUrlsContext.Provider>
        <EntryDragPreview />
      </FileUploadProvider>
    </Fragment>
    </Provider>
  );
}

function Navbar() {
    const { isMobileMode } = useContext(DashboardLayoutContext);
    const activePage = useDriveStore(s => s.activePage);
  
    const children = isMobileMode ? null : <NavbarSearch />;
    const searchButton = (
      <IconButton elementType={Link} to={SearchPage.path}>
        <SearchIcon />
      </IconButton>
    );
  
    const mobileRightChildren = (
      <Fragment>
        {activePage !== SearchPage && searchButton}
        <CreateNewButton isCompact />
      </Fragment>
    );
  
    return (
      <Fragment>
        <DashboardNavbar
          rightChildren={isMobileMode && mobileRightChildren}
          menuPosition="drive-navbar"
        >
          {children}
        </DashboardNavbar>
        {isMobileMode && <FloatingActionList />}
      </Fragment>
    );
  }
  
  function FloatingActionList() {
    const entriesSelected = useDriveStore(s => s.selectedEntries.size);
    if (!entriesSelected) return null;
    return (
      <div className="fixed right-0 top-0 z-10 flex h-54 w-full items-center justify-center gap-10 rounded bg-primary px-6 text-on-primary shadow-xl">
        <IconButton
          onClick={() => {
            driveState().selectEntries([]);
          }}
        >
          <CloseIcon />
        </IconButton>
        <Trans message=":count selected" values={{ count: entriesSelected }} />
        <EntryActionList className="ml-auto" />
      </div>
    );
  }