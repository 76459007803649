import React from 'react'
import ImageCircle from './ImageCircle';

interface inboxItemProbs{
    userImage: string;
    name: string;
    time: string;
    displayedMessage: string;
    active?: boolean;
}

export default function InboxItem({userImage, name, time, displayedMessage, active=false}: inboxItemProbs) {
    
  return (
    <div className={`${active ? 'bg-[#0154A01A]' : 'hover:bg-alt cursor-pointer'} flex w-full gap-8 p-16 rounded-[16px]` }>
      <div>
      <ImageCircle image={userImage}></ImageCircle>
      </div>
      <div className='flex-grow'>
        <div className='flex w-full items-center justify-between'>
            <h3 className='font-[500] text-primary text-[14px] leading-[21px]'>{name}</h3>
            <h5 className='text-[#888888] text-[10px] leading-[15px] content-center'>{time}</h5>
        </div>
        <h4 className={`mt-6 line-clamp-2 leading-[18px] text-[12px] font-[500] ${active ? 'text-primary' : 'text-[#888888]'}`}>{displayedMessage}</h4>
      </div>
    </div>
  )
}
