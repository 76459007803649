import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {Button} from '@ui/buttons/button';
import {Form} from '@ui/forms/form';
import {LoginPayload, useLogin} from '../requests/use-login';
import {FormCheckbox} from '@ui/forms/toggle/checkbox';
import {LinkStyle} from '@ui/buttons/external-link';
import {SocialAuthSection} from './social-auth-section';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '@ui/i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useContext} from 'react';
import {
  SiteConfigContext,
  SiteConfigContextValue,
} from '../../core/settings/site-config-context';
import {useSettings} from '@ui/settings/use-settings';
import companyLogo from '../../../../../../public/images/company-logo.png';

interface Props {
  onTwoFactorChallenge: () => void;
}
export function LoginPage({onTwoFactorChallenge}: Props) {
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();

  const isWorkspaceLogin = pathname.includes('workspace');
  const searchParamsEmail = searchParams.get('email') || undefined;

  const {branding, registration, site, social} = useSettings();
  const siteConfig = useContext(SiteConfigContext);

  const demoDefaults =
    site.demo && !searchParamsEmail ? getDemoFormDefaults(siteConfig) : {};
  const form = useForm<LoginPayload>({
    defaultValues: {remember: true, email: searchParamsEmail, ...demoDefaults},
  });
  const login = useLogin(form);

  const heading = isWorkspaceLogin ? (
    <Trans
      values={{siteName: branding?.site_name}}
      message="To join your team on :siteName, login to your account"
    />
  ) : (
    <>
    <img src={companyLogo} className='w-[129px] block mx-auto' alt="" />
    <div className='text-center text-2xl text-[#0154a0] text-[32px] font-[600] poppins'>
      <Trans message="Sign in"/>
    </div>
    <h2 className='font-[500] text-base text-center text-[#888] mt-10 poppins'>Welcome back! Please enter your details.</h2>
    </>
  );

  const message = !registration?.disable && (
    <Trans
      values={{
        a: parts => (
          <Link className={'text-main'} to="/register">
            {parts}
          </Link>
        ),
      }}
      message="Don't have an account? <a>Sign up.</a>"
    />
  );

  const isInvalid = !!Object.keys(form.formState.errors).length;

  return (
    <AuthLayout heading={heading} message={message}>
      <StaticPageTitle>
        <Trans message="Login" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={payload => {
          login.mutate(payload, {
            onSuccess: response => {
              if (response.two_factor) {
                onTwoFactorChallenge();
              }
            },
          });
        }}
      >
        <FormTextField
          className="mb-12"
          name="email"
          type="email"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Email" /></span>}
          disabled={!!searchParamsEmail}
          invalid={isInvalid}
          required
        />
        <FormTextField
          className="mb-12"
          name="password"
          type="password"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Password" /></span>}
          invalid={isInvalid}
          required
        />
        <div className='flex justify-between items-center flex-wrap my-20'>
        <FormCheckbox name="remember" className="remember-login block text-[12px] text-[#888] fonr-[500] poppins w-full sm:w-auto">
          <Trans message="Remember for 30 days" />
        </FormCheckbox>
        <Link className={`text-main text-[12px] font-[500] poppins mt-10 w-full sm:w-auto sm:mt-0`} to="/forgot-password" tabIndex={-1}>
              <Trans message="Forgot your password?" />
            </Link>
        
        </div>
        <Button
          className="block w-full bg-main hover:bg-main text-white rounded-[8px]"
          type="submit"
          variant="flat"
          size="md"
          disabled={login.isPending}
        >
          <Trans message="Continue" />
        </Button>
      </Form>
      <SocialAuthSection
        dividerMessage={
            <span className='poppins text-[14px] font-[500]'><Trans message="Or" /></span>
        }
      />
    </AuthLayout>
  );
}

function getDemoFormDefaults(siteConfig: SiteConfigContextValue) {
  if (siteConfig.demo.loginPageDefaults === 'randomAccount') {
    // random number between 0 and 100, padded to 3 digits
    const number = Math.floor(Math.random() * 100) + 1;
    const paddedNumber = String(number).padStart(3, '0');
    return {
      email: `admin@demo${paddedNumber}.com`,
      password: 'admin',
    };
  } else {
    return {
      email: siteConfig.demo.email ?? 'admin@admin.com',
      password: siteConfig.demo.password ?? 'admin',
    };
  }
}
