import PlusIcon from '@app/drive/layout/icons/PlusIcon'
import React, { useEffect, useState } from 'react'

interface AddChatModalProbs{
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddChatModal({open, setOpen} : AddChatModalProbs) {

    let [modalOpen, setModalOpen] = useState(open);

    useEffect(()=>{
        setModalOpen(open);
    }, [open])

  return (
    <div className={` top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 flex items-center justify-center ${modalOpen ? 'fixed': 'hidden'}`}>
        <div className='w-full max-w-[450px] bg-white rounded p-20'>
            <div className='flex justify-between items-center'>
                <h3 className='text-black font-[500] text-xl'>Add Chat</h3>
                <button className='text-black' onClick={()=>{setModalOpen(false); setOpen(false)}}><i className='fa-solid fa-x'></i></button>
            </div>
            <hr />
            <div className='mt-16'>
                <form>
                    <label htmlFor="chatEmail" className='text-black'>reciever email</label>
                    <input type="email" id='chatEmail' name='chat_email' className='bg-[#EAEBF0] py-12 px-20 rounded-[8px] w-full' required/>
                    <div className='flex w-full justify-end mt-10'>
                        <button type='submit' className='bg-primary px-20 py-6 rounded'>
                            <div className='flex items-center gap-8 text-white'>
                                <i className='fa-solid fa-plus'></i>
                                <h4>Add</h4>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}
