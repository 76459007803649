import { Link, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { LinkStyle } from '@ui/buttons/external-link';
import { RegisterPayload, useRegister } from '../requests/use-register';
import { SocialAuthSection } from './social-auth-section';
import { AuthLayout } from './auth-layout/auth-layout';
import { Trans } from '@ui/i18n/trans';
import { FormCheckbox } from '@ui/forms/toggle/checkbox';
import { CustomMenuItem } from '../../menus/custom-menu';
import { useRecaptcha } from '../../recaptcha/use-recaptcha';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useSettings } from '@ui/settings/use-settings';
import React, { useContext } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import companyLogo from '../../../../../../public/images/company-logo.png';
import { FormRadioGroup } from '@ui/forms/radio-group/radio-group';
import { FormRadio } from '@ui/forms/radio-group/radio';

interface Props {
  inviteType?: string;
}
export function RegisterPage({ inviteType }: Props) {
  const { branding, registration, social } = useSettings();
  const { auth } = useContext(SiteConfigContext);
  const { verify, isVerifying } = useRecaptcha('register');

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isRegisteringUsingInvite =
    pathname.includes('workspace') || !!inviteType;
  const isBillingRegister = searchParams.get('redirectFrom') === 'pricing';
  const searchParamsEmail = searchParams.get('email') || undefined;

  const form = useForm<RegisterPayload>({
    defaultValues: { email: searchParamsEmail },
  });
  const register = useRegister(form);

  if (registration?.disable && !isRegisteringUsingInvite) {
    return <Navigate to="/login" replace />;
  }

  let heading = <>
    <img src={companyLogo} className='w-[129px] block mx-auto' alt="" />
    <div className='text-center text-2xl text-[#0154a0] text-[32px] font-[600] poppins'>
      <Trans message="Sign up" />
    </div>
    <h2 className='font-[500] text-base text-center text-[#888] mt-10 poppins'>Welcome back! Please enter your details.</h2>
  </>;
  if (isRegisteringUsingInvite) {
    heading = (
      <Trans
        values={{ siteName: branding?.site_name }}
        message="To join your team on :siteName, create an account"
      />
    );
  } else if (isBillingRegister) {
    heading = <Trans message="First, let's create your account" />;
  }

  const message = (
    <Trans
      values={{
        a: parts => (
          <Link className={'text-main'} to="/login">
            {parts}
          </Link>
        ),
      }}
      message="Already have an account? <a>Sign in.</a>"
    />
  );

  return (
    <AuthLayout heading={heading} message={message}>
      <StaticPageTitle>
        <Trans message="Register" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={async payload => {
          const isValid = await verify();
          if (isValid) {
            register.mutate({
              ...payload,
              invite_type: inviteType,
            });
          }
        }}
      >
        <FormTextField
          className="mb-12"
          name="first_name"
          type="text"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="First Name" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="last_name"
          type="text"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Second Name" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="email"
          type="email"
          disabled={!!searchParamsEmail}
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Email" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="password"
          type="password"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Password" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="password_confirmation"
          type="password"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Confirm password" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="age"
          type="number"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Age" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="job_occubation"
          type="text"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Job occupation" /></span>}
          required
        />
        <FormTextField
          className="mb-12"
          name="add_company"
          type="text"
          label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Company name" /></span>}
          required
        />

        <FormRadioGroup 
        name='gender' 
        label={<span className='text-black text-[14px] poppins font-[500]'><Trans message="Gender" /></span>}
        orientation='horizontal'
        >
          <FormRadio value='male' id='maleGender'></FormRadio>
          <label htmlFor="maleGender"><span className='text-black text-[14px] poppins font-[500]'><Trans message="Male" /></span></label>
          <FormRadio value='female' id='femaleGender' ></FormRadio>
          <label htmlFor="femaleGender"><span className='text-black text-[14px] poppins font-[500]'><Trans message="Female" /></span></label>
        </FormRadioGroup>

        {/* <span className='text-black text-[14px] poppins font-[500] block'><Trans message="Gender" /></span>
        <div className='flex items-center gap-20'>
          <div className='flex justify-start items-center gap-5'>
            <input type="radio" name="gender" id="maleGender" value={'male'} className='mt-5' defaultChecked/>
            <label htmlFor="maleGender"><span className='text-black text-[14px] poppins font-[500]'><Trans message="Male" /></span></label>
          </div>
          <div className='flex justify-start items-center gap-5'>
            <input type="radio" name="gender" id="femaleGender" value={'female'} className='mt-5' />
            <label htmlFor="femaleGender"><span className='text-black text-[14px] poppins font-[500]'><Trans message="Female" /></span></label>
          </div>
        </div> */}
        {auth?.registerFields ? <auth.registerFields /> : null}
        <PolicyCheckboxes />
        <Button
          className="block w-full bg-main text-white hover:bg-main mt-20"
          type="submit"
          variant="flat"
          size="md"
          disabled={register.isPending || isVerifying}
        >
          <Trans message="Create account" />
        </Button>
        <SocialAuthSection
          dividerMessage={
            <span className='poppins text-[14px] font-[500]'><Trans message="Or" /></span>
          }
        />
      </Form>
    </AuthLayout>
  );
}

function PolicyCheckboxes() {
  const { registration } = useSettings();

  if (!registration?.policies) return null;

  return (
    <div className="mb-32">
      {registration.policies.map(policy => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="mb-4 block"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}
